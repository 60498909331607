<div class="p-4" style="background-color: rgb(245, 238, 230); height: 100vh;">
  <div class="container">
    <div [ngStyle]="{'background-image': 'url(' + getClientBannerUrl() + ')'}"
         class="custom-background"
         style="background-position: center top;">
    </div>

    <div class="form-wrapper">
      <div class="thank-you-container">
        <h2 class="card-title">Process Over</h2>
        <p class="card-text">We are unable to proceed further as consent was not provided. If you have any questions, please contact us.</p>
      </div>
    </div>
  </div>
  <div class="text-center mt-3">
    <p>
      Please contact <a href="mailto:info@dandilegal.co.uk" style="color: rgb(156, 86, 6); font-weight: bold;">info@dandilegal.co.uk</a> if you are experiencing any issues with our form.
    </p>
  </div>
</div>
