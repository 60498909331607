import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserFormService {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient) { }

  submitForm(formData: any, atsName: string): Observable<any> {
    const endpoint = `${this.baseUrl}/submit-` + atsName;
    return this.http.post(endpoint, formData);
  }

  getGCSESchools(period: string): Observable<string[]> {
    const endpoint = `${this.baseUrl}/gcse-schools-endpoint?period=${period}`;
    return this.http.get<string[]>(endpoint);
  }

  getALevelSchools(period: string): Observable<string[]> {
    const endpoint = `${this.baseUrl}/alevel-schools-endpoint?period=${period}`;
    return this.http.get<string[]>(endpoint);
  }
}
