import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.less']
})
export class ConsentComponent implements OnInit {
  consentShareInformation: string = 'yes';  // Default value, assuming 'yes'
  clientName: string = '';

  constructor(private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit() {
    this.clientName = this.route.snapshot.queryParams['clientName'];
  }

  getClientBannerUrl(): string {
    if (this.clientName) {
      return `assets/${this.clientName}_Banner.jpg`;
    }

    return 'assets/Dandi_Banner.jpg';
  }

  onContinue() {
    const queryParams = this.route.snapshot.queryParams;

    if (this.consentShareInformation === 'yes') {
      this.router.navigate(['/form'], { queryParams });
    }
    else {
      this.router.navigate(['/terminated'], { queryParams });

    }
  }
}
