<!-- nav -->
<nav class="navbar navbar-expand navbar-dark bg-dark px-3" *ngIf="user">
  <div class="navbar-nav">
    <a class="nav-item nav-link" routerLink="/ats" routerLinkActive="active">ATS</a>
    <a class="nav-item nav-link" routerLink="/upload" routerLinkActive="active">Upload Data</a>
    <button class="btn btn-link nav-item nav-link" (click)="logout()">Logout</button>
  </div>
</nav>

<!-- main app container -->
<div [ngClass]="{ 'bg-light': user }">
  <div>
    <router-outlet></router-outlet>
  </div>
</div>
