import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserFormService } from '../_services/user-form.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { map, Observable, startWith } from 'rxjs';

@Component({
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.less']
})
export class UserFormComponent implements OnInit {
  dandiForm!: FormGroup;
  // Define the options for the dropdown menus
  yesNoDcaOptions = ['Yes', 'No', 'Decline to answer'];
  yesNoDcaOutsideOptions = ['Yes', 'No', 'Decline to answer or lived outside of the UK'];
  schoolTypeOptions = ['UK state/selective state/academy', 'UK Independent/fee-paying', 'UK independent/fee paying (government or bursary funded)', 'International', 'Decline to answer'];
  workHoursOptions = ['None', '1-7 hours per week', '8-15 hours per week', '16+ hours per week', 'Decline to answer'];
  aLevelGradeOptions = ['A*', 'A', 'B', 'C', 'D', 'E'];
  levelOptions = ['AS-Level', 'A-level Double', 'Applied A-level', 'Pre-U', 'Scottish Higher', 'Advanced Higher', 'Baccalaureate', 'Higher Level', 'Ordinary Level', 'BTEC', 'T-Level', 'EPQ', 'Other'];
  occupations = [
    'Modern professional and traditional professional occupations such as: teacher, nurse, physiotherapist, social worker, musician, police officer (sergeant or above), software designer, accountant, solicitor, medical practitioner, scientist, civil or mechanical engineer.',
    'Senior, middle or junior managers or administrators such as: finance manager, chief executive, large business owner, office manager, retail manager, bank manager, restaurant manager, warehouse manager.',
    'Clerical and intermediate occupations such as: secretary, personal assistant, call centre agent, clerical worker, nursery nurse.',
    'Technical and craft occupations such as: motor mechanic, plumber, printer, electrician, gardener, train driver.',
    'Routine, semi-routine manual and service occupations such as: postal worker, machine operative, security guard, caretaker, farm worker, catering assistant, sales assistant, HGV driver, cleaner, porter, packer, labourer, waiter or waitress, bar staff.',
    'Long-term unemployed (claimed Jobseeker’s Allowance or earlier unemployment benefit for more than a year).',
    'Small business owners who employed less than 25 people such as: corner shop owners, small plumbing companies, retail shop owner, single restaurant or cafe owner, taxi owner, garage owner.',
    'Other such as: retired, this question does not apply to me, I don’t know.',
    'I prefer not to say.'
  ];
  gcseSchools: string[] = [];
  filteredGcseSchools: Observable<string[]> | undefined;

  aLevelSchools: string[] = [];
  filteredALevelSchools: Observable<string[]> | undefined;

  aLevelYears: string[] = [];
  gsceYears: string[] = [];

  clientName: string = '';
  candidateId: string = '';
  atsName: string = '';
  numberOfAdditionalSubjects = 0; // You can set the initial number of additional subjects

  additionalEquivalentSubjects: { index: number }[] = [];
  lastAddedEquivalent = 4;

  additionalSubjects: { index: number }[] = [];
  lastAdded = 4;

  providerExtraData: Object | undefined;

  controlLabels = [
    { label: 'Local Authority', controlName: 'localAuthority' },
    { label: 'Refugee', controlName: 'refugee' },
    { label: 'School Type', controlName: 'schoolType' },
    { label: 'First Generation University', controlName: 'firstGenUni' },
    { label: 'Free Meals', controlName: 'freeMeals' },
    { label: 'Russell Group', controlName: 'russellGroup' },
    { label: 'Work during 16-18', controlName: 'work1618' },
    { label: 'Work during University', controlName: 'workUni' },
    { label: 'Education Carer', controlName: 'educationCarer' },
    { label: 'Education Medical', controlName: 'educationMedical' },
    { label: 'Education Family', controlName: 'educationFamily' },
    { label: 'Postcode or Parental Occupational', controlName: 'postcode' },
    { label: 'UK Residency', controlName: 'ukResidency' },
    { label: 'GCSE Year', controlName: 'gcseYear' },
    { label: 'GCSE School', controlName: 'gcseSchool' },
    { label: 'A-Level Year', controlName: 'aLevelYear' },
    { label: 'A-Level School', controlName: 'aLevelSchool' },
    { label: 'A-Level Completion', controlName: 'aLevelCompletion' },
    { label: 'A-Level Grade 1', controlName: 'aLevelGrade1' },
    { label: 'A-Level Grade 2', controlName: 'aLevelGrade2' },
    { label: 'A-Level Grade 3', controlName: 'aLevelGrade3' }
  ];

  constructor(private fb: FormBuilder, private userFormService: UserFormService, private toastr: ToastrService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit(): void {
    // Initialize the form group with form controls and validators
    this.dandiForm = this.fb.group({
      localAuthority: ['', Validators.required],
      refugee: ['', Validators.required],
      schoolType: ['', Validators.required],
      firstGenUni: ['', Validators.required],
      freeMeals: ['', Validators.required],
      russellGroup: ['', Validators.required],
      work1618: ['', Validators.required],
      workUni: ['', Validators.required],
      educationCarer: ['', Validators.required],
      educationMedical: ['', Validators.required],
      educationFamily: ['', Validators.required],
      postcode: ['', Validators.required],
      ukResidency: ['Yes', Validators.required], // Default value set to 'Yes'

      // GCSE Results Section
      gcseYear: ['', Validators.required],
      gcseSchool: [''],

      // A-Level Results Section
      aLevelYear: ['', Validators.required],
      aLevelSchool: [''],
      aLevelCompletion: ['3ALevels', Validators.required],

      // A-Level Grades Section
      aLevelSubject1: [''],
      aLevelGrade1: ['', Validators.required],
      aLevelSubject2: [''],
      aLevelGrade2: ['', Validators.required],
      aLevelSubject3: [''],
      aLevelGrade3: ['', Validators.required],

      // A-Level Equivalent Examinations Section
      otherSubject1: [''],
      otherGrade1: [''],
      otherSubject2: [''],
      otherGrade2: [''],
      otherSubject3: [''],
      otherGrade3: [''],
      otherLevel: [''],

      householdOccupation: ['']
    });

    this.dandiForm.get('gcseYear')?.valueChanges.subscribe((newYear) => {
      var gcseSchool = this.dandiForm.get('gcseSchool');
      if (newYear == "Pre 2014") {
        gcseSchool?.setValue("Unavailable");
        gcseSchool?.disable();
      }
      else {
        gcseSchool?.enable();
        gcseSchool?.setValue("");

        this.userFormService.getGCSESchools(newYear)
          .subscribe(schools => {
            this.gcseSchools = schools;

            this.filteredGcseSchools = this.dandiForm.get('gcseSchool')?.valueChanges.pipe(
              startWith(''),
              map(value => {
                const name = typeof value === 'string' ? value : value;
                return name ? this._filterGSCE(name as string) : this.gcseSchools.slice();
              })
            );
          });
      }
    });

    this.dandiForm.get('aLevelYear')?.valueChanges.subscribe((newYear) => {
      var aLevelSchool = this.dandiForm.get('aLevelSchool');
      if (newYear == "Pre 2012") {
        aLevelSchool?.setValue("Unavailable");
        aLevelSchool?.disable();
      }
      else {
        aLevelSchool?.enable();
        aLevelSchool?.setValue("");

        this.userFormService.getALevelSchools(newYear)
          .subscribe(schools => {
            this.aLevelSchools = schools;

            this.filteredALevelSchools = this.dandiForm.get('aLevelSchool')?.valueChanges.pipe(
              startWith(''),
              map(value => {
                const name = typeof value === 'string' ? value : value;
                return name ? this._filterALevel(name as string) : this.aLevelSchools.slice();
              })
            );
          });
      }
    });

    this.dandiForm.get('ukResidency')?.valueChanges.subscribe((value) => {
      const postcodeControl = this.dandiForm.get('postcode');
      if (value === 'No') {
        // If the user selects 'No', disable the postcode control
        postcodeControl?.clearValidators();
        postcodeControl?.setValue("");
      }
      else {
        postcodeControl?.setValidators(Validators.required);
      }
      postcodeControl?.updateValueAndValidity();
    });

    this.dandiForm.get('aLevelCompletion')?.valueChanges.subscribe((value) => {
      const aLevelGrade1Control = this.dandiForm.get('aLevelGrade1');
      const aLevelGrade2Control = this.dandiForm.get('aLevelGrade2');
      const aLevelGrade3Control = this.dandiForm.get('aLevelGrade3');

      if (value === '3ALevels') {
        aLevelGrade1Control?.setValidators(Validators.required);
        aLevelGrade2Control?.setValidators(Validators.required);
        aLevelGrade3Control?.setValidators(Validators.required);
      }
      else {
        aLevelGrade1Control?.clearValidators();
        aLevelGrade2Control?.clearValidators();
        aLevelGrade3Control?.clearValidators();
      }

      aLevelGrade1Control?.updateValueAndValidity();
      aLevelGrade2Control?.updateValueAndValidity();
      aLevelGrade3Control?.updateValueAndValidity();
    });

    const currentYear = new Date().getFullYear();
    this.gsceYears = this.getYears(2014, 2024);
    this.aLevelYears = this.getYears(2012, 2026);

    this.dandiForm.patchValue({
      gcseYear: currentYear.toString(),
      aLevelYear: currentYear.toString()
    });

    this.candidateId = this.route.snapshot.queryParams['candidateId'];
    this.atsName = this.route.snapshot.queryParams['atsName'];
    this.clientName = this.route.snapshot.queryParams['clientName'];

    if (this.atsName == "eploy")
      this.providerExtraData = this.route.snapshot.queryParams['applicationId'];
  }

  getClientBannerUrl(): string {
    if (this.clientName) {
      return `assets/${this.clientName}_Banner.jpg`;
    }

    return 'assets/Dandi_Banner.jpg';
  }

  showPostCode() {
    return this.dandiForm.get('ukResidency')?.value === 'Yes';
  }

  getAdditionalSubjectsArray(): number[] {
    return Array.from({ length: this.additionalSubjects.length }, (_, index) => index);
  }

  addAdditionalSubject(): void {
    this.additionalSubjects.push({ index: this.lastAdded });
    this.lastAdded++;
  }

  removeAdditionalSubject(index: number) {
    this.additionalSubjects = this.additionalSubjects.filter(x => x.index != index);
  }

  getAdditionalEquivalentSubjectsArray(): number[] {
    return Array.from({ length: this.additionalEquivalentSubjects.length }, (_, index) => index);
  }

  addAdditionalEquivalentSubject(): void {
    this.additionalEquivalentSubjects.push({ index: this.lastAddedEquivalent });
    this.lastAddedEquivalent++;
  }

  removeAdditionalEquivalentSubject(index: number) {
    this.additionalEquivalentSubjects = this.additionalEquivalentSubjects.filter(x => x.index != index);
  }

  private _filterGSCE(name: string): string[] {
    const filterValue = name.toLowerCase();
    return this.gcseSchools.filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterALevel(name: string): string[] {
    const filterValue = name.toLowerCase();
    return this.aLevelSchools.filter(option => option.toLowerCase().includes(filterValue));
  }

  getYears(startYear: number, endYear: number): string[] {
    const years: string[] = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push(year.toString());
    }

    years.push('Pre ' + startYear);// Add default option
    return years;
  }

  is3ALevelsSelected(): boolean {
    return this.dandiForm.get('aLevelCompletion')?.value === '3ALevels';
  }

  isOtherEquivalentSelected(): boolean {
    return this.dandiForm.get('aLevelCompletion')?.value === 'otherEquivalent';
  }

  // Submit the form data
  onSubmit(): void {
    var ProviderExtraData = '';
    if (this.atsName == 'eploy') {
      ProviderExtraData = this.route.snapshot.queryParams['actionId'];
    }

    let nameALevel = this.dandiForm.get('aLevelSchool')?.value || '';
    let schoolNameALevel = nameALevel;
    let townNameALevel = '';
    const lastIndex = nameALevel.lastIndexOf(',');

    if (lastIndex !== -1) {
      schoolNameALevel = nameALevel.substring(0, lastIndex);
      townNameALevel = nameALevel.substring(lastIndex + 2);
    }

    let nameGSCE = this.dandiForm.get('gcseSchool')?.value || '';
    let schoolNameGSCE = nameGSCE;
    let townNameGSCE = '';
    const lastIndexGSCE = nameGSCE.lastIndexOf(',');

    if (lastIndexGSCE !== -1) {
      schoolNameGSCE = nameGSCE.substring(0, lastIndexGSCE);
      townNameGSCE = nameGSCE.substring(lastIndexGSCE + 2);
    }

    const formData = {
      CandidateId: this.candidateId,
      ATSName: this.atsName,
      ProviderExtraData: ProviderExtraData,
      ClientName: this.clientName,

      PostCode: this.dandiForm.get('postcode')?.value,
      LocalAuthority: this.dandiForm.get('localAuthority')?.value,
      Refugee: this.dandiForm.get('refugee')?.value,
      FreeMeals: this.dandiForm.get('freeMeals')?.value,
      UkState: this.dandiForm.get('schoolType')?.value,
      FirstGenUni: this.dandiForm.get('firstGenUni')?.value,
      RussellGroup: this.dandiForm.get('russellGroup')?.value,
      Work1618: this.dandiForm.get('work1618')?.value,
      WorkUni: this.dandiForm.get('workUni')?.value,
      EducationMedical: this.dandiForm.get('educationMedical')?.value,
      EducationFamily: this.dandiForm.get('educationFamily')?.value,
      EducationCarer: this.dandiForm.get('educationCarer')?.value,

      GSCEPeriod: this.dandiForm.get('gcseYear')?.value,
      SchoolNameGSCE: schoolNameGSCE,
      TownNameGSCE: townNameGSCE,
      ALevelPeriod: this.dandiForm.get('aLevelYear')?.value,
      SchoolNameALevel: schoolNameALevel,
      TownNameALevel: townNameALevel,
      ALevelG1: this.dandiForm.get('aLevelGrade1')?.value,
      ALevelG2: this.dandiForm.get('aLevelGrade2')?.value,
      ALevelG3: this.dandiForm.get('aLevelGrade3')?.value,
      ALevelSubject1: this.dandiForm.get('aLevelSubject1')?.value,
      ALevelSubject2: this.dandiForm.get('aLevelSubject2')?.value,
      ALevelSubject3: this.dandiForm.get('aLevelSubject3')?.value,

      OtherGrade1: this.dandiForm.get('otherGrade1')?.value,
      OtherSubject1: this.dandiForm.get('otherSubject1')?.value,
      OtherGrade2: this.dandiForm.get('otherGrade2')?.value,
      OtherSubject2: this.dandiForm.get('otherSubject2')?.value,
      OtherGrade3: this.dandiForm.get('otherGrade3')?.value,
      OtherSubject3: this.dandiForm.get('otherSubject3')?.value,
      OtherLevel: this.dandiForm.get('otherLevel')?.value,
      HouseholdOccupation: '',
    };

    if (this.dandiForm.get('ukResidency')?.value === 'No') {
      formData.PostCode = "NoPostCodeCode";
      formData.HouseholdOccupation = this.dandiForm.get('householdOccupation')?.value;
    }

    if (this.dandiForm.get('aLevelCompletion')?.value == 'otherEquivalent') {
      formData.ALevelG1 = 'YY';
      formData.ALevelG2 = 'YY';
      formData.ALevelG3 = 'YY';
    }

    this.userFormService.submitForm(formData, this.atsName).subscribe(response => {
      const queryParams = this.route.snapshot.queryParams;

      this.router.navigate(['/thank-you'], { queryParams });
    });
  }
}
