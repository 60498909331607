import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.less']
})
export class ThankYouComponent implements OnInit {
  clientName: string = '';

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.clientName = this.route.snapshot.queryParams['clientName'];
  }

  getClientBannerUrl(): string {
    if (this.clientName) {
      return `assets/${this.clientName}_Banner.jpg`;
    }

    return 'assets/Dandi_Banner.jpg';
  }
}
