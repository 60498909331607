<div class="p-4" style="background-color: rgb(245, 238, 230)">
  <div class="container">
    <div [ngStyle]="{'background-image': 'url(' + getClientBannerUrl() + ')'}"
         class="custom-background"
         style="background-position: center top;">
    </div>

    <div style=" background-color: rgb(156, 86, 6); border-top-left-radius: 8px; border-top-right-radius: 8px; height: 10px; max-width: 640px; margin-right: auto; margin-left: auto; margin-top: 20px;">

    </div>
    <div style="max-width: 640px; width: 100%; padding: 20px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); background: white; margin-right: auto; margin-left: auto; ">
      <b style="font-weight: 400; font-size: 24pt; line-height: 1.25; letter-spacing: 0; margin-bottom:10px">Dandi Social Mobility Questionnaire</b>
      <div class="cBGGJ OIC90c" dir="auto" style="margin-top:20px;">
        <p>
          <em>
            We would like to understand your personal circumstances and educational background in more detail, to ensure we can put your fantastic achievements in context. Upon submission of the form, you understand that we share the following information with Dandi: your unique candidate/application ID, postcode, education grades and history, the data you provide under ‘contextual information’ and the fact that you applying for a new job opportunity.
          </em>
        </p>

        <p>
          <em>
            Your information is anonymised and we do not share any other information with Dandi. Weightmans will be the data controller in respect of the use of this data. Dandi can use your information in an anonymised way for statistical use only and would be the data controller in this instance.
          </em>
        </p>
      </div>

      <div class="consent-section">
        <p>
          <em>
            Please tick 'Yes' if you agree that we may share your annoymised information with Dandi for your application to be reviewed contextually and for statistical use only
          </em>
        </p>
        <mat-radio-group [(ngModel)]="consentShareInformation">
          <mat-radio-button [value]="'yes'">YES</mat-radio-button>
          <mat-radio-button [value]="'no'">NO</mat-radio-button>
        </mat-radio-group>
      </div>
      <button type="button" (click)="onContinue()">Continue</button>
    </div>
  </div>
  <div class="text-center mt-3">
    <p>
      Please contact <a href="mailto:info@dandilegal.co.uk" style="color: rgb(156, 86, 6); font-weight: bold;">info@dandilegal.co.uk</a> if you are experiencing any issues with our form.
    </p>
  </div>
</div>
