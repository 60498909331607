import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsentComponent } from './consent/consent.component';
import { HomeComponent } from './home';
import { TerminatedComponent } from './terminated/terminated.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { UserFormComponent } from './user-form/user-form.component';
import { AuthGuard } from './_helpers';

const atsModule = () => import('./ats/ats.module').then(x => x.ATSModule);
const uploadModule = () => import('./upload/upload.module').then(x => x.UploadModule);
const accountModule = () => import('./account/account.module').then(x => x.AccountModule);

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'ats', loadChildren: atsModule, canActivate: [AuthGuard] },
  { path: 'upload', loadChildren: uploadModule, canActivate: [AuthGuard] },
  { path: 'account', loadChildren: accountModule },
  { path: 'consent', component: ConsentComponent },
  { path: 'form', component: UserFormComponent },
  { path: 'thank-you', component: ThankYouComponent },
  { path: 'terminated', component: TerminatedComponent },
  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
